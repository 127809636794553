import { useSelector } from "react-redux";
import { LatmPocInterface, SplitCardInterface } from "../../interface/redux-state/LatmPocInterface";

export function LatmpocSplitCardComponent(this: any) {
  const latmPocState = useSelector((state: any) => state.latmpoc) as LatmPocInterface;

  const rowFn = (card: SplitCardInterface, index: number) => {
    return (
      <tr key={index}>
        <td>{card.customerName}</td>
        <td>{card.cardBrand}</td>
        <td>{card.lastFourDigits}</td>
        <td>{card.ccExp}</td>
        <td>{card.amount}</td>
      </tr>
    );
  };

  return (
    <>
      <div className="latmpocTableHeadPad">
        {latmPocState.numberOfSplitCards > "0" ? (
          <table className="latmpocTable">
            <thead>
              <tr>
                <th>Name</th>
                <th>Card Brand</th>
                <th>Card#</th>
                <th>Expires</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>{latmPocState.splitCards.map((row, index) => rowFn(row, index))}</tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
