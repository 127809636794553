import { Latm3PocComponent } from "./Latm3PocComponent";

export function Latm3PocScreen() {
  return (
    <>
      <Latm3PocComponent compPos={0} />
      <Latm3PocComponent compPos={1} />
      <Latm3PocComponent compPos={2} />
    </>
  );
}
