import type { Draft } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Latm3PocInterface, Latm3PocCardInterface, UserCardInterface } from "../../interface/redux-state/Latm3PocInterface";

export const initialUserCards: UserCardInterface = {
  cardBrand: "",
  ccAccountNum: "",
  lastFourDigits: "",
  bin: "",
  ccExp: "",
  customerName: "",
  customerFatherName: "",
  customerMotherName: "",
  payChoice: "",
  amount: "",
  balanceAmount: "",
  cardNum: 0,
};

const initialCardState: Latm3PocCardInterface = {
  cardBrand: "",
  cardValid: false,
  cvvValid: false,
  cvvEmpty: false,
  ccExpMonth: "",
  ccExpYear: "",
  customerName: "",
  customerFatherName: "",
  customerMotherName: "",
  payChoice: "PAYBALANCE",
  amount: "",
  error: {
    customerNameMissing: false,
    customerNameMinLength: false,
    customerFatherNameMissing: false,
    customerFatherMinLength: false,
    customerMotherNameMissing: false,
    creditCardMissing: false,
    creditCardInvalid: false,
    cvvMissing: false,
    cvvInvalid: false,
    cardExpired: false,
    amountMissing: false,
    amountValueError: false,
    amountTotalError: false,
  },
};

const initialState: Latm3PocInterface = {
  loading: true,
  cardInfo: [initialCardState, initialCardState, initialCardState],
  balanceAmounts: ["", "", ""],
  processing: false,
  clearCardField: [false, false, false],
  microform: [null, null, null],
  scrollHeight: "",
};

export const latm3PocSlice = createSlice({
  name: "latm3poc",
  initialState,
  reducers: {
    resetState: (state: Draft<Latm3PocInterface>) => {
      state.loading = initialState.loading;
      state.cardInfo = initialState.cardInfo;
      state.balanceAmounts = initialState.balanceAmounts;
      state.processing = initialState.processing;
      state.clearCardField = [false, false, false];
      state.microform = initialState.microform;
      state.scrollHeight = initialState.scrollHeight;
    },
    changeState: (state: Draft<Latm3PocInterface>, action) => {
      state.cardInfo[action.payload.cardNum].cardBrand = action.payload.cardBrand;
      state.cardInfo[action.payload.cardNum].cardValid = action.payload.cardValid;
      state.cardInfo[action.payload.cardNum].cvvValid = action.payload.cvvValid;
      state.cardInfo[action.payload.cardNum].cvvEmpty = action.payload.cvvEmpty;
      state.cardInfo[action.payload.cardNum].ccExpMonth = action.payload.ccExpMonth;
      state.cardInfo[action.payload.cardNum].ccExpYear = action.payload.ccExpYear;
      state.cardInfo[action.payload.cardNum].customerName = action.payload.customerName;
      state.cardInfo[action.payload.cardNum].customerFatherName = action.payload.customerFatherName;
      state.cardInfo[action.payload.cardNum].customerMotherName = action.payload.customerMotherName;
      state.cardInfo[action.payload.cardNum].error = action.payload.error;
      state.cardInfo[action.payload.cardNum].payChoice = action.payload.payChoice;
      state.cardInfo[action.payload.cardNum].amount = action.payload.amount;
    },
    setTestingDate: (state: Draft<Latm3PocInterface>, action) => {
      state.cardInfo[action.payload.cardNum].ccExpMonth = action.payload.ccExpMonth;
      state.cardInfo[action.payload.cardNum].ccExpYear = action.payload.ccExpYear;
    },
    setCvv: (state: Draft<Latm3PocInterface>, action) => {
      state.cardInfo[action.payload.cardNum].cvvValid = action.payload.cvvValid;
      state.cardInfo[action.payload.cardNum].cvvEmpty = action.payload.cvvEmpty;
      state.cardInfo[action.payload.cardNum].error.cvvInvalid = !action.payload.cvvValid;
      state.cardInfo[action.payload.cardNum].error.cvvMissing = !action.payload.cvvEmpty;
    },
    setCardType: (state: Draft<Latm3PocInterface>, action) => {
      state.cardInfo[action.payload.cardNum].cardValid = action.payload.cardValid;
      state.cardInfo[action.payload.cardNum].cardBrand = action.payload.cardBrand;
      state.cardInfo[action.payload.cardNum].error.creditCardInvalid = action.payload.creditCardInvalid;
      state.cardInfo[action.payload.cardNum].error.creditCardMissing = action.payload.creditCardMissing;
    },
    loading: (state: Draft<Latm3PocInterface>, action) => {
      state.loading = action.payload;
    },
    updateError: (state: Draft<Latm3PocInterface>, action) => {
      state.cardInfo[action.payload.cardNum].error.customerNameMissing = action.payload.customerNameMissing;
      state.cardInfo[action.payload.cardNum].error.customerNameMinLength = action.payload.customerNameMinLength;
      state.cardInfo[action.payload.cardNum].error.customerFatherNameMissing = action.payload.customerFatherNameMissing;
      state.cardInfo[action.payload.cardNum].error.customerFatherMinLength = action.payload.customerFatherMinLength;
      state.cardInfo[action.payload.cardNum].error.customerMotherNameMissing = action.payload.customerMotherNameMissing;
      state.cardInfo[action.payload.cardNum].error.creditCardMissing = action.payload.creditCardMissing;
      state.cardInfo[action.payload.cardNum].error.creditCardInvalid = action.payload.creditCardInvalid;
      state.cardInfo[action.payload.cardNum].error.cvvMissing = action.payload.cvvMissing;
      state.cardInfo[action.payload.cardNum].error.cvvInvalid = action.payload.cvvInvalid;
      state.cardInfo[action.payload.cardNum].error.cardExpired = action.payload.cardExpired;
      state.cardInfo[action.payload.cardNum].error.amountMissing = action.payload.amountMissing;
      state.cardInfo[action.payload.cardNum].error.amountValueError = action.payload.amountValueError;
      state.cardInfo[action.payload.cardNum].error.amountTotalError = action.payload.amountTotalError;
    },
    setProcessing: (state: Draft<Latm3PocInterface>, action) => {
      state.processing = action.payload.processing;
    },
    setChoice: (state: Draft<Latm3PocInterface>, action) => {
      state.cardInfo[action.payload.cardNum].payChoice = action.payload.payChoice;
    },
    setBalanceAmount: (state: Draft<Latm3PocInterface>, action) => {
      state.balanceAmounts[action.payload.cardNum] = action.payload.balanceAmount;
    },
    setAllBalanceAmounts: (state: Draft<Latm3PocInterface>, action) => {
      state.balanceAmounts = [action.payload.balanceAmount0, action.payload.balanceAmount1, action.payload.balanceAmount2];
    },
    resetForNewCard: (state: Draft<Latm3PocInterface>, action) => {
      state.loading = initialState.loading;
      state.cardInfo[action.payload.cardNum].cardBrand = initialState.cardInfo[action.payload.cardNum].cardBrand;
      state.cardInfo[action.payload.cardNum].cardValid = initialState.cardInfo[action.payload.cardNum].cardValid;
      state.cardInfo[action.payload.cardNum].cvvValid = initialState.cardInfo[action.payload.cardNum].cvvValid;
      state.cardInfo[action.payload.cardNum].cvvEmpty = initialState.cardInfo[action.payload.cardNum].cvvEmpty;
      state.cardInfo[action.payload.cardNum].customerName = initialState.cardInfo[action.payload.cardNum].customerName;
      state.cardInfo[action.payload.cardNum].customerFatherName = initialState.cardInfo[action.payload.cardNum].customerFatherName;
      state.cardInfo[action.payload.cardNum].customerMotherName = initialState.cardInfo[action.payload.cardNum].customerMotherName;
      state.cardInfo[action.payload.cardNum].error = initialState.cardInfo[action.payload.cardNum].error;
      state.cardInfo[action.payload.cardNum].payChoice = "PAYBALANCE";
      state.cardInfo[action.payload.cardNum].amount = initialState.cardInfo[action.payload.cardNum].amount;
      state.processing = initialState.processing;
      state.cardInfo[action.payload.cardNum].ccExpMonth = action.payload.ccExpMonth;
      state.cardInfo[action.payload.cardNum].ccExpYear = action.payload.ccExpYear;
      state.clearCardField[action.payload.cardNum] = initialState.clearCardField[action.payload.cardNum];
    },
    resetCard: (state: Draft<Latm3PocInterface>, action) => {
      state.cardInfo[action.payload.cardNum].cardBrand = initialCardState.cardBrand;
      state.cardInfo[action.payload.cardNum].cardValid = initialCardState.cardValid;
      state.cardInfo[action.payload.cardNum].cvvValid = initialCardState.cvvValid;
      state.cardInfo[action.payload.cardNum].cvvEmpty = initialCardState.cvvEmpty;
      state.cardInfo[action.payload.cardNum].customerName = initialCardState.customerName;
      state.cardInfo[action.payload.cardNum].customerFatherName = initialCardState.customerFatherName;
      state.cardInfo[action.payload.cardNum].customerMotherName = initialCardState.customerMotherName;
      state.cardInfo[action.payload.cardNum].payChoice = initialCardState.payChoice;
      state.cardInfo[action.payload.cardNum].amount = initialCardState.amount;
      state.cardInfo[action.payload.cardNum].error.customerNameMissing = initialCardState.error.customerNameMissing;
      state.cardInfo[action.payload.cardNum].error.customerNameMinLength = initialCardState.error.customerNameMinLength;
      state.cardInfo[action.payload.cardNum].error.customerFatherNameMissing = initialCardState.error.customerFatherNameMissing;
      state.cardInfo[action.payload.cardNum].error.customerFatherMinLength = initialCardState.error.customerFatherMinLength;
      state.cardInfo[action.payload.cardNum].error.customerMotherNameMissing = initialCardState.error.customerMotherNameMissing;
      state.cardInfo[action.payload.cardNum].error.creditCardMissing = initialCardState.error.creditCardMissing;
      state.cardInfo[action.payload.cardNum].error.creditCardInvalid = initialCardState.error.creditCardInvalid;
      state.cardInfo[action.payload.cardNum].error.cvvMissing = initialCardState.error.cvvMissing;
      state.cardInfo[action.payload.cardNum].error.cvvInvalid = initialCardState.error.cvvInvalid;
      state.cardInfo[action.payload.cardNum].error.cardExpired = initialCardState.error.cardExpired;
      state.cardInfo[action.payload.cardNum].error.amountMissing = initialCardState.error.amountMissing;
      state.cardInfo[action.payload.cardNum].error.amountValueError = initialCardState.error.amountValueError;
      state.cardInfo[action.payload.cardNum].error.amountTotalError = initialCardState.error.amountTotalError;
      state.clearCardField[action.payload.cardNum] = true;
    },
    clearCardField: (state: Draft<Latm3PocInterface>, action) => {
      state.clearCardField[action.payload.cardNum] = action.payload.newstate;
    },
    setMicroform: (state: Draft<Latm3PocInterface>, action) => {
      state.microform[action.payload.cardNum] = action.payload.microform;
    },
    setScrollHeight: (state: Draft<Latm3PocInterface>, action) => {
      state.scrollHeight = action.payload.scrollHeight;
    },
  },
});

export const {
  resetState,
  changeState,
  setCvv,
  setCardType,
  loading,
  updateError,
  setProcessing,
  setChoice,
  resetForNewCard,
  setBalanceAmount,
  setAllBalanceAmounts,
  setTestingDate,
  resetCard,
  clearCardField,
  setMicroform,
  setScrollHeight,
} = latm3PocSlice.actions;

export default latm3PocSlice;
