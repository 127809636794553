import { ApiConstants } from "../utils/ApiConstants";
import axios from "axios";

export default class CpgmuiService {
  public static submitServiceOperation(data: any) {
    return axios.post(ApiConstants.execServiceOperation, data);
  }
  public static submitServiceOperationRest(data: any) {
    return axios.post(ApiConstants.execServiceOperationRest, data);
  }
}
