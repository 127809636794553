const DECIMAL_POINT = ".";

export const formatAsCurrency = (amount: string) => {
  return "$" + formatAmount(amount);
};

export const formatAmount = (amount: string) => {
  let number: any = amount;

  // Always ensure that the number is a minimum of 2 digits long so the process does not throw an exception if 1 cent (1) is passed in
  if (amount === undefined) {
    return "0.00";
  } else if (amount.length < 2) {
    number = "0" + amount;
  }

  // If the number does not contain a decimal, insert a decimal before the last two digits since decimals are implied
  if (number.indexOf(DECIMAL_POINT)) {
    number = number.substring(0, number.length - 2) + DECIMAL_POINT + number.substring(number.length - 2);
  }

  number = (Math.round(number * 100) / 100).toFixed(2);

  return number;
};

// Create our number formatter.
export const formatterCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options can be used to round to whole numbers.
  //trailingZeroDisplay: 'stripIfInteger' // This is probably what most people
  // want. It will only stop printing
  // the fraction when the input
  // amount is a round number (int)
  // already. If that's not what you
  // need, have a look at the options
  // below.
  minimumFractionDigits: 0, // This suffices for whole numbers, but will
  // print 2500.10 as $2,500.1
  maximumFractionDigits: 0, // Causes 2500.99 to be printed as $2,501
});

export const formatAmountWithDecimal = (amount: string) => {
  let number: any = amount;

  number = number.replace("$", "");

  // Always ensure that the number is a minimum of 2 digits long so the process does not throw an exception if 1 cent (1) is passed in
  if (amount === undefined) {
    return "0.00";
  } else if (amount.length < 2) {
    number = "0" + amount;
  }

  if (number.indexOf(DECIMAL_POINT) < 0) {
    // if there is no cents then add
    number = number + ".00";
  }

  number = (Math.round(number * 100) / 100).toFixed(2);

  return "$" + number;
};
