import configSlice from "./configSlice";
import tceppsSlice from "./tcepps_slice";
import tceppsProxySlice from "./tceppsProxy_slice";
import paymentSlice from "./paymentSlice";
import s21CsaMyCsaSlice from "./s21CsaMyCsaSlice";
import s21CsaSlice from "./s21CsaSlice";
import rossPocSlice from "./rossPocSlice";
import cpgmuiSlice from "./cpgmuiSlice";
import latmPocSlice from "./latmPocSlice";
import latm3PocSlice from "./latm3PocSlice";
import instlpayPocSlice from "./instlpayPocSlice";

const rootReducer = () => {
  return {
    config: configSlice.reducer,
    tcepps: tceppsSlice.reducer,
    tceppsProxy: tceppsProxySlice.reducer,
    paymentInfo: paymentSlice.reducer,
    s21csamycsa: s21CsaMyCsaSlice.reducer,
    s21csa: s21CsaSlice.reducer,
    rosspoc: rossPocSlice.reducer,
    cpgmui: cpgmuiSlice.reducer,
    latmpoc: latmPocSlice.reducer,
    latm3poc: latm3PocSlice.reducer,
    instlpaypoc: instlpayPocSlice.reducer,
  };
};

export default rootReducer;
