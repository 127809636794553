import { parseToken } from "./utils";

export const createCybersourceToken = (expirationMonth: string, expirationYear: string, microform: any) => {
  const options = {
    expirationMonth,
    expirationYear,
  };

  return new Promise((resolve, reject) => {
    microform.createToken(options, (err: any, token: any) => {
      let newErrMsg = "";
      if (err) {
        switch (err.reason) {
          case "CREATE_TOKEN_NO_FIELDS_LOADED":
            newErrMsg = "991: Token creation error, no fields have been loaded." + err.message;
            break;
          case "CREATE_TOKEN_TIMEOUT":
            newErrMsg = "991: createToken call was unable to proceed." + err.message;
            break;
          case "CREATE_TOKEN_XHR_ERROR":
            newErrMsg = "991: Network error when attempting to create a token." + err.message;
            break;
          case "CREATE_TOKEN_NO_FIELDS":
            newErrMsg = "991: Data fields are unavailable for collection." + err.message;
            break;
          case "CREATE_TOKEN_VALIDATION_PARAMS":
            newErrMsg = "991: Issue with parameters supplied to createToken." + err.message;
            break;
          case "CREATE_TOKEN_VALIDATION_FIELDS":
            // @ts-ignore
            if (err.details && err.details.length > 0) {
              // @ts-ignore
              if (err.details.length > 1) {
                newErrMsg =
                  "Please enter a credit card number. Please check that your Credit card verification code is correct or contact your card issuer.";
              } else if (err.details[0].location === "number") {
                newErrMsg = "Please enter a credit card number.";
              } else if (err.details[0].location === "securityCode") {
                newErrMsg = "Please check that your Credit card verification code is correct or contact your card issuer.";
              } else {
                newErrMsg = err.message;
              }
            } else {
              newErrMsg = err.message;
            }
            break;
          case "CREATE_TOKEN_VALIDATION_SERVERSIDE":
            newErrMsg = "991: Server-side validation rejects the createToken request." + err.message;
            break;
          case "CREATE_TOKEN_UNABLE_TO_START":
            newErrMsg = "991: Loaded field was able to handle the createToken request." + err.message;
            break;
          default:
            newErrMsg = "991: Unknown error:" + err.message;
            break;
        }
        reject(newErrMsg);
      } else {
        resolve(parseToken(token));
      }
    });
  });
};
